import React from "react"
import Detail from "../../BlogDetail/Detail"
import { Grid } from "@mui/material"
import "./Content.scss"
import "../blogpage.scss"
import VisibilitySensor from "react-visibility-sensor"
import { Link, Element } from "react-scroll"
import TalkToUs from "../../../shared/TalkToUs"
import Head from "../../../utils/Head"
import Framework from "/static/blog/Blog Detail Optimized/What is Product Discovery Framework & Examples.jpg"
import GreatStrategy from "/static/blog/Blog Detail Optimized/What is a Great Product Development Strategy.jpg"

function BlogSeventeen() {
  const layoutData = {
    name: "Product Strategy",
    heading: "What Is Product Discovery? Framework & Examples",
    published: "Published: 28 June, 2024",
    authors: [
      {
        name: "Sikandar Lak",
        desig: "Content Strategist",
        avatar: "/aboutUs/pictures/sikander.png",
      },
      {
        name: "Hamza Munir",
        desig: "CEO",
        avatar: "/aboutUs/pictures/hamza.png",
      },
    ],
    heroImg: Framework,
    description: (
      <>
        {"There are many variations of"} <br />{" "}
        {"passages of Lorem Ipsum available, but the majority have suffered."}
      </>
    ),

    desc: <div></div>,
  }

  const checkVisibilityOff = visibility => {
    const el = document.getElementById("table-of-content")
    if (visibility) {
      el.style.display = "none"
    }
  }
  const checkVisibilityOn = visibility => {
    const el = document.getElementById("table-of-content")
    if (visibility) {
      el.style.display = "block"
      el.style.position = "fixed"
    }
  }

  return (
    <>
      <Head
        metaTitle="What Is Product Strategy? Framework & Examples - CodeFulcrum"
        title="What Is Product Strategy? Framework & Examples"
        desc="Learn the importance of a clear product strategy that aligns your business goals with your users' needs and product vision to drive success."
      />
      <Detail layoutData={layoutData}>
        <div className="blog-content container">
          <Grid container spacing={3}>
            <Grid item md={9}>
              <Element name="What Is Product Discovery?">
                <VisibilitySensor
                  onChange={checkVisibilityOn}
                  delayedCall
                  scrollCheck
                  intervalDelay={10}
                >
                  <h2 className="header">What Is Product Discovery?</h2>
                </VisibilitySensor>

                <p className="text">
                  {" "}
                  Choosing what to create is the process of product discovery.
                  It outlines a number of things you must complete before
                  beginning the product distribution procedure (the development
                  of your product). Without both discovery and delivery, an
                  application cannot be developed. These two procedures are
                  crucial for a project to be successful.
                  <br />
                  <br />
                  The "issue space" and the "solution space" must be balanced
                  when the product discovery process is being conducted. In
                  general, the problem space focuses on the issues that need to
                  be resolved, while the solution space is concerned with coming
                  up with the best solutions possible. The "business space"
                  should also be covered as part of the discovery process. 
                  <br />
                  <br />
                  Market shifts are often driven by new technology, changing
                  information, and competition. It is crucial for businesses to
                  adapt to these developments to remain relevant. A common
                  strategy is to launch new products quickly, in order to gain
                  market share and increase revenue."
                  <br />
                  <br />
                  Companies may determine whether a potential product concept is
                  viable on the market by using product discovery. To establish
                  what a minimal viable product (MVP) may be or to iterate on
                  the MVP, product discovery approaches are frequently used in
                  companies. Understanding product market fit is the main goal
                  of discovery.
                </p>
              </Element>

              <div className="talk-class">
                <TalkToUs
                  btnText={"TALK TO US"}
                  header="Product discovery is a crucial step in the product development process, but it can be difficult to navigate."
                  text={
                    "Let our team guide you through the framework and provide you with real-life examples."
                  }
                />
              </div>
              <Element name="Product Discovery Framework and Examples">
                <VisibilitySensor
                  onChange={checkVisibilityOn}
                  delayedCall
                  scrollCheck
                  intervalDelay={10}
                >
                  <h2 className="header">
                    Product Discovery Framework and Examples
                  </h2>
                </VisibilitySensor>

                <p className="text">
                  We can teach product teams and agile teams in continuous
                  product discovery with the aid of the product discovery
                  framework, which gives the product discovery process
                  structure.
                  <br />
                  <br />
                  Initially, people are often excited when they first encounter
                  Product Discovery, but they may become overwhelmed by its
                  unpredictable nature. They may not know what, when, or how
                  much to do. To address this challenge, we developed a
                  framework for Product Discovery that provides structure to the
                  creative process of deciding what to build.
                  <br />
                  <br />
                  The process of product discovery may be complicated. It is
                  non-linear and unexpected, and every week, the amount and
                  nature of the labor may change dramatically. The renowned PDCA
                  cycle is essentially implemented in the Product Discovery
                  Framework for the two key tasks that must be completed in
                  order to properly verify product ideas: exploration and
                  validation.
                </p>
                <h3 className="sub-header">Design Thinking</h3>
                <p className="text">
                  Design thinking has evolved into a fundamental idea and
                  framework for product discovery. This method of examining the
                  needs of the product is customer-focused.
                  <br />
                  <br />
                  The discovery phase is the first step in a design project,
                  which may involve teams of different sizes and various
                  research or workshop activities, all aimed at understanding a
                  problem area and reaching agreement on desired outcomes. By
                  gathering information about customer demands and coming up
                  with innovative solutions, design thinking enables businesses
                  to create products that are focused on their target audience.
                  It focuses on what is ethically right, economically sound, and
                  practically possible. Because of this, design thinking is a
                  fantastic tool for efforts to identify new products.
                </p>
                <h3 className="sub-header">Exploration Phase</h3>
                <p className="text">
                  The problem space is explored in the exploration loop. By
                  integrating qualitative and quantitative research methods, we
                  aim to truly grasp the demands and pain areas of our
                  consumers.
                </p>
                <h3 className="sub-header">Research</h3>
                <p className="text">
                  To better understand user and consumer demands, research is
                  conducted. Here, you must mix quantitative research methods
                  like surveys or market research with qualitative methods like
                  consumer observation or interviews.
                  <br />
                  <br />
                  The jobs-to-be-done structure has shown to be extremely
                  effective in really understanding problems and creating
                  cutting-edge solutions.
                </p>
                <h3 className="sub-header">Evaluate</h3>
                <p className="text">
                  Prioritizing possibilities found during the Ideation phase and
                  choosing which ones go to the Validation loop are the goals of
                  evaluation.
                </p>
                <h3 className="sub-header">Validation Phase</h3>
                <p className="text">
                  Exploring the solution space is part of the validation loop.
                  We put customer demands into the form of prototypes and assess
                  them at various degrees of detail.
                </p>
                <h3 className="sub-header">Prototype</h3>
                <p className="text">
                  There are several sorts of prototypes you could want to
                  develop, as well as various tools to build those prototypes,
                  depending on what you want to validate and how accurately you
                  want it to be done. Your validation questions or the product
                  hypothesis you wish to test will guide how you construct your
                  trials.
                </p>
                <h3 className="sub-header">Test</h3>
                <p className="text">
                  The same prototype can be used for a variety of testing.
                  Sometimes the prototype serves as the actual test, and other
                  times it serves as the foundation for many testing.
                  <br />
                  <br />
                  We provide you with a variety of templates to help you arrange
                  your hypotheses, turn them into successful tests, create
                  experiment pairings, and develop trial funnels.
                </p>
                <h3 className="sub-header">Risk assumption test</h3>
                <p className="text">
                  The concept of an MVP should be recognizable to anyone who
                  works in product management or software engineering. The
                  minimally viable product must have the necessary functionality
                  to provide the end user with the benefits of the main product.
                  MVP is a tool used to demonstrate that a team is doing quality
                  work.
                  <br />
                  <br />
                  RAT is a related idea that focuses on testing the riskiest
                  assumption before moving on to the delivery stage, rather than
                  developing an MVP. The RAT approach advises testing areas of
                  greatest uncertainty, while still creating a demonstration
                  product for testing and demonstration purposes.
                </p>
                <h3 className="sub-header">Example</h3>
                <p className="text">
                  In 2014, Amazon unveiled a stylish gadget that ran a fully
                  modified version of Android. The "Fire Phone" could locate a
                  scanned item on Amazon and detect noises, text, and objects.
                  Additionally, it cost $199 and had subpar hardware and no
                  Google services. The phone didn't seem or perform poorly. Who
                  needs a phone to shop on Amazon, was the query.
                  <br />
                  <br />
                  The iPhone 6 was released in September 2014; therefore the
                  market for smartphones was still in its infancy. However,
                  Amazon killed the smartphone industry instead of competition,
                  declaring a $170 million loss related to the Fire Phone and
                  ending its manufacturing a year later. Short version: The Fire
                  Phone came to symbolize a product without a market.
                  <br />
                  <br />
                  What therefore should we learn? Any product must first
                  determine its market fit. Before we start building, we need to
                  decide what to create and who will require it. Discovery and
                  delivery are the two stages of product development.
                </p>
                <div className="talk-class">
                  <VisibilitySensor
                    onChange={checkVisibilityOn}
                    delayedCall
                    scrollCheck
                    intervalDelay={10}
                  >
                    <TalkToUs
                      btnText={"Read full blog"}
                      header="What is a Great Product Development Strategy?"
                      text={
                        "Corporate business strategies including goals for expansion, profitability, diversification, and market entry are connected to product development strategies."
                      }
                      image={GreatStrategy}
                      ctaLink={
                        "/blogs/what-is-a-great-product-development-strategy"
                      }
                    />
                  </VisibilitySensor>
                </div>
              </Element>
              <Element name="Conclusion">
                <h2 className="header">Conclusion</h2>
                <VisibilitySensor
                  onChange={checkVisibilityOff}
                  delayedCall
                  scrollCheck
                  intervalDelay={10}
                >
                  <p className="text">
                    When using an off-the-shelf solution, you can't anticipate
                    that all of your problems will be fixed as soon as you'd
                    want. You are only one of many users, and the roadmap will
                    be followed for all product changes. So, your only option is
                    to wait. Additionally, bear in mind that business conditions
                    are subject to change. The method that things are done might
                    vary depending on a number of variables, including the law
                    and market conditions. Custom Software Companies can modify
                    the running programme to fit the altered business
                    environment if necessary. The business analysts can evaluate
                    the effects modifications will have on the company. All
                    needs will be examined and documented, which will aid in
                    estimating how much work remains. A business analyst gives
                    the development team the precise specifications by acting as
                    a "bridge" between the business problem and the
                    technological solution to it. The job of altering the
                    functioning application won't require much time and effort
                    because engineers will be aware of your company's demands.
                  </p>
                </VisibilitySensor>
                <br />
                <br />
                <div> </div>
              </Element>
            </Grid>
            <Grid item md={3}>
              <div id="table-of-content" className="table-of-content">
                <p className="header-table">Table of Content</p>
                <ul>
                  <Link
                    activeClass="active"
                    to="What Is Product Discovery?"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>What Is Product Discovery?</li>
                  </Link>
                  <Link
                    activeClass="active"
                    to="Product Discovery Framework and Examples"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>Product Discovery Framework and Examples</li>
                  </Link>
                  <Link
                    activeClass="active"
                    to="Conclusion"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>Conclusion</li>
                  </Link>
                </ul>

                <p className="share">Share</p>
                <div className="link-icons d-flex justify-content-between">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/linkedin.svg"
                      alt="linkedin"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </a>

                  <a
                    href="https://instagram.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/instagram.svg"
                      alt="instagram"
                      className="img-fluid"
                      loading="lazy"
                      id="insta"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/facebook.svg"
                      alt="facebook"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Detail>
    </>
  )
}

export default BlogSeventeen
